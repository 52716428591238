.sidebar{
    min-width: 250px;
    width: 25%;
    background: red;
}

.tab{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-radius: 4px;
    will-change: background-color;
    transition: background-color 0.2s ease-out;
    cursor: pointer;
}

.tab:hover{
    background-color: #f6f6f6;
}

.tab.active{
    background-color: #EEEEEE;
}
