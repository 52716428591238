.task-menu{
    position: absolute;
    top:0;
    right: 0;
    z-index: 1;
    background: white;
    padding: 8px 20px;
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    line-height: 16px;
}

.task-menu_button{
    margin: 12px 0;
    cursor: pointer;
}

.task-menu_button-remove{
    color: #CB6060;
}
