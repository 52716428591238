.tasks-list{
    padding: 0 39px;
    width: 100%;
}


.tasks-list_title{
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    color: #3A3A3A;
    margin-bottom: 18px;
    cursor: default;
}

.task-creator{
    margin-bottom: 16px;
}

.block{
    position: relative;
}
