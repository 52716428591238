.custom-input{
    position: relative;
}

.custom-input_input{
    border: 2px solid;
    border-color: #A6A6A6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    color: #3A3A3A;
    transition: all .2s ease-in;
}

.custom-input_input:focus{
    border: 2px solid #5B5B5B;
    outline: none;
}

.custom-input_title{
    background: white;
    color: #525252;
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 8px;
    cursor: default;
}
/**/
