.registration-window{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.registration-window_window{
    background: white;
    position: relative;
    z-index: 11;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
}

.registration-window_window_title{
    font-size: 24px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 46px;
}

.registration-window_window_checkbox{
    max-width: 360px;
}

.registration-window_window .custom-input{
    margin-bottom: 28px;
    font-size: 14px;
    color: #525252;
    display: flex;
}
.registration-window_window .custom-input_input{
    width: 360px;
}

.registration-window_window .button{
    margin-top: 45px;
}

.registration-window_window_go-to-login-btn{
    margin-top: 16px;
    font-size: 14px;
    color: #525252;
    cursor: pointer;
}
