.authorization-window{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.authorization-window_window{
    background: white;
    position: relative;
    z-index: 11;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
}

.authorization-window_window_title{
    font-size: 24px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 46px;
}



.authorization-window_window .custom-input{
    margin-bottom: 28px;
    font-size: 14px;
    color: #525252;
    display: flex;
}
.authorization-window_window .custom-input_input{
    width: 360px;
}

.authorization-window_window .button{
    margin-top: 26px;
}

.authorization-window_window_go-to-reg-btn{
    margin-top: 16px;
    font-size: 14px;
    color: #525252;
    cursor: pointer;
}


.authorization-window_window .authorization-window_window_input-password{
    margin-bottom: 12px;

}

.authorization-window_window_forgot-password{
    align-self: flex-end;
    cursor: pointer;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    color: #525252;
}
