.settings-window{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.settings-window_window{
    background: white;
    position: relative;
    z-index: 11;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 360px;
}

.settings-window_window_title{
    font-size: 24px;
    text-align: center;
    color: #3A3A3A;
    margin-bottom: 24px;
}


.settings-window_window .custom-input{
    margin-bottom: 28px;
    font-size: 14px;
    color: #525252;
    display: flex;
}
.settings-window_window .custom-input_input{
    width: 360px;
}

.settings-window_window .button{
    margin-top: 45px;
}

.settings-window_window_go-to-login-btn{
    margin-top: 16px;
    font-size: 14px;
    color: #525252;
    cursor: pointer;
}
.settings-window_window_user_photo{
    width: 100px;
    height: 100px;
    margin-right: 24px;


}
.settings-window_window_user{
    align-items: center;
    display: flex;
    align-self: flex-start;
    margin-bottom: 32px;
}
.settings-window_window_user .button{
    margin-top: 0;
}

