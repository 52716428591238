.profile{
    display: flex;
    align-items: center;
}

.profile_photo{
    height: 52px;
    width: 52px;
    border-radius: 50%;
    margin-right: 16px;
}

.profile_info_name{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #3A3A3A;
    margin-bottom: 4px;
    cursor: pointer;
}

.profile_info_today-tasks{
    font-size: 14px;
    color: #525252;
    cursor: default;
}
