.task-creator{
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.45);
    border-radius: 4px;
    padding: 14px;
    width: 100%;
}


.task-creator .custom-input input{
    width: 100%;
}

.task-creator_bottom{
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    align-items: center;
}

.task-creator_bottom_date{
    display: flex;
}

.task-creator_bottom_date_title{
    font-size: 16px;
    color: #3A3A3A;
    cursor: default;
    margin-right: 6px;
}

.task-creator .button{
    padding: 8px 16px;
    min-width: 100px;
    max-width: 120px;
    font-size: 16px;
    cursor: pointer;
}
