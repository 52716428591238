
.task-card{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 8px;
    align-items: center;
}

.task-card .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-card_date{
    margin-top: 10px;
    font-size: 12px;
    color: #525252;
}

.task-card_left{
    display: flex;
    align-items: center;
}

.task-card_left_title{
    font-size: 16px;
    color: #525252;
}

.task-card input[type="checkbox"]+label:before{
    border-radius: 50%;
    margin-right: 20px;
}

.task-card_menu-btn{
    width: 16px;
    height: 16px;
    background: #BFBFBF;
    margin-right: 4px;
}

