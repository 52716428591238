.App {
    font-family: 'Roboto', sans-serif;
    background: #ECECEC;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.h-break{
    width: 100%;
    height: 2px;
    background: #EEEEEE;
    margin: 11px 0;
}

.full-window-dark-background{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 10;
    background: rgba(0, 0, 0, 0.54);
}

input[type="checkbox"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
input[type="checkbox"]+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
input[type="checkbox"]+label::before {
    content: '';
    display: inline-block;
    align-self: flex-start;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #A6A6A6;
    border-radius: 2px;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
input[type="checkbox"]:checked+label::before {
    border-color: #5CBC6C;
    background-color: #5CBC6C;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.button{
    width: 210px;
    border-radius: 4px;
    padding: 14px 16px;
    background: #A6A6A6;
    cursor: pointer;
    color: white;
    border: none;
    font-size: 18px;
    text-align: center;
}

.button.green{
    background: #5CBC6C;
}
